import type { RequestParams } from "@tamarack/sdk";
import { AppUrl } from "@tamarack/shared/AppUrl";
import { DEFAULT_PAGE_SIZE } from "@tamarack/ui/TablePagination";
import { loaderSearchParams } from "./url";

/**
 * TODO: this class is confused about whether it deals with pageable objects or
 * pageable url search params. Need to split it apart into 2 objects
 */
export class Pageable {
  static prefixedParam(param: string, pageableKey?: string) {
    if (pageableKey) {
      return `${pageableKey}-${param}`;
    }

    return param;
  }

  static emptyPage<T>() {
    const content: T[] = [];
    return {
      content,
      totalElements: 0,
      totalPages: 0,
      empty: true,
      first: true,
      last: true,
      number: 0,
      numberOfElements: 0,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: false,
        unpaged: true,
      },
      size: 0,
    };
  }

  searchParams: URLSearchParams;
  pageableKey?: string;

  constructor(
    data?: Request | URLSearchParams | URL | AppUrl | undefined | null,
    pageableKey?: string
  ) {
    let searchParams = data;

    if (!searchParams) {
      searchParams = new URLSearchParams();
    }

    if (searchParams instanceof URLSearchParams) {
      this.searchParams = searchParams;
    } else if (searchParams instanceof URL) {
      this.searchParams = searchParams.searchParams;
    } else if (searchParams instanceof AppUrl) {
      this.searchParams = searchParams.searchParams;
    } else {
      this.searchParams = loaderSearchParams(searchParams);
    }

    this.pageableKey = pageableKey;
  }

  getPageKey() {
    return Pageable.prefixedParam(PAGE_PARAM, this.pageableKey);
  }

  getPage() {
    return this.searchParams.get(this.getPageKey())!;
  }

  hasPage() {
    return this.searchParams.has(this.getPageKey());
  }

  getAllPages() {
    return this.searchParams.getAll(this.getPageKey());
  }

  setPage(value: string | number) {
    this.searchParams.set(this.getPageKey(), value.toString());
    return this;
  }

  appendPage(value: string | number) {
    this.searchParams.append(this.getPageKey(), value.toString());
    return this;
  }

  deletePage() {
    this.searchParams.delete(this.getPageKey());
    return this;
  }

  deleteAllPages() {
    for (const param of this.searchParams.keys()) {
      if (param.includes(PAGE_PARAM)) {
        this.searchParams.delete(param);
      }
    }

    return this;
  }

  getSizeKey() {
    return Pageable.prefixedParam(SIZE_PARAM, this.pageableKey);
  }

  getSize() {
    return this.searchParams.get(this.getSizeKey());
  }

  getAllSizes() {
    return this.searchParams.getAll(this.getSizeKey());
  }

  setSize(value: string | number): Pageable {
    this.searchParams.set(this.getSizeKey(), value.toString());
    return this;
  }

  appendSize(value: string | number) {
    this.searchParams.append(this.getSizeKey(), value.toString());
    return this;
  }

  deleteSize() {
    this.searchParams.delete(this.getSizeKey());
    return this;
  }

  deleteAllSizes() {
    for (const param of this.searchParams.keys()) {
      if (param.includes(SIZE_PARAM)) {
        this.searchParams.delete(param);
      }
    }
    return this;
  }

  getSort() {
    return this.searchParams.get(Pageable.prefixedParam(SORT_PARAM, this.pageableKey));
  }

  getAllSorts() {
    return this.searchParams.getAll(Pageable.prefixedParam(SORT_PARAM, this.pageableKey));
  }

  setSort(value: string) {
    this.searchParams.set(Pageable.prefixedParam(SORT_PARAM, this.pageableKey), value.toString());
    return this;
  }

  appendSort(value: string) {
    this.searchParams.append(
      Pageable.prefixedParam(SORT_PARAM, this.pageableKey),
      value.toString()
    );
    return this;
  }

  deleteSort() {
    this.searchParams.delete(Pageable.prefixedParam(SORT_PARAM, this.pageableKey));
    return this;
  }

  deleteAllSorts() {
    for (const param of this.searchParams.keys()) {
      if (param.includes(SORT_PARAM)) {
        this.searchParams.delete(param);
      }
    }
    return this;
  }

  toJSON() {
    const page = parseInt(this.getPage() ?? "0", 10);
    const size = parseInt(this.getSize() ?? `${DEFAULT_PAGE_SIZE}`, 10);
    const sort = this.getAllSorts();

    return { page, size, sort };
  }

  toSearchParams() {
    return this.searchParams;
  }
}

/**
 * @deprecated use the Pagination class
 */
export function deleteAllPageParams(searchParams: URLSearchParams) {
  return new Pageable(searchParams).deleteAllPages();
}

/**
 * @deprecated use the Pageable class
 */
export const PAGE_PARAM = "page";
/**
 * @deprecated use the Pageable class
 */
export const SIZE_PARAM = "size";
/**
 * @deprecated use the Pageable class
 */
export const SORT_PARAM = "sort";

/**
 * Loosely defines a parent interface that should be shared across the Page types defined by OpenAPI.
 */
export interface Page<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  /** @format int32 */
  number: number;
  /** @format int32 */
  numberOfElements: number;
  /** @format int32 */
  size: number;
  /** @format int64 */
  totalElements: number;
  /** @format int32 */
  totalPages: number;
}

/**
 * Gets all available pages by calling the given API get function.
 *
 * NOTE: This is only meant for scripting purposes, it's not intended for front-end use.
 *
 * @param fGet The API get function to call to retrieve each page
 * @param pageSize The number of objects to include in each page
 * @returns The results from all pages of the get request
 */
export async function getAll<T>(
  fGet: (query?: object, params?: RequestParams) => Promise<Page<T>>,
  pageSize?: number
) {
  const pageable = new Pageable(new URLSearchParams({ page: "0" })).appendSize(pageSize ?? 1000);
  const results: T[] = [];
  let response: Page<T>;
  do {
    response = await fGet({ ...pageable.toJSON() });
    results.push(...response.content);
    pageable.setPage(parseInt(pageable.getPage(), 10) + 1);
  } while (!response.last);

  return results;
}
