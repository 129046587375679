import { useLocation } from "@remix-run/react";
import { AppUrl } from "@tamarack/shared/AppUrl";

export const REDIRECT_TO_KEY = "redirectTo";
export const RETURN_TO_PARAM = "returnTo";

export function getTenantFromUrl(requestUrl: string) {
  const url = new URL(requestUrl);

  const tenantName = url.searchParams.get("tenantName");

  if (tenantName) {
    return tenantName;
  }

  const parts = url.hostname.split(".");
  if ((parts[parts.length - 1] === "localhost" && parts.length === 2) || parts.length === 3) {
    return parts[0];
  } else {
    return undefined;
  }
}

export function loaderSearchParams(request: Request): URLSearchParams {
  return new URL(request.url).searchParams;
}

export const getSearchParamFromRequest = (request: Request, param: string) => {
  const url = new URL(request.url);
  return url.searchParams.get(param) ?? undefined;
};

export function getBooleanSearchParam(
  searchParams: URLSearchParams,
  key: string
): boolean | undefined {
  if (searchParams.has(key)) {
    const value = searchParams.get(key);
    return value === "true" ? true : value === "false" ? false : undefined;
  } else {
    return undefined;
  }
}
export function getBooleanSearchParamValue(value?: boolean): string | undefined {
  switch (value) {
    case true:
      return "true";
    case false:
      return "false";
    case undefined:
      return undefined;
  }
}

/**
 * If a value exists in an appended search param, it removes it.
 * If it doesn't exist, it adds it.
 *
 * All the comparisons are by the value of a given key
 */
export const addOrRemoveSearchParamByValue = (
  searchParams: URLSearchParams,
  key: string,
  value: string
) => {
  let values = searchParams.getAll(key);

  if (values.includes(value)) {
    values = values.filter((v) => v !== value);
  } else {
    values.push(value);
  }

  searchParams.delete(key);
  values.forEach((v) => searchParams.append(key, v));

  return searchParams;
};

export const loginRedirectUrl = (redirectPath: string, redirectSearchParams?: URLSearchParams) => {
  return new AppUrl("/login").appendSearchParam(
    REDIRECT_TO_KEY,
    new AppUrl(redirectPath).appendSearchParams(redirectSearchParams).toString()
  );
};

export function useReturnUrlParam() {
  const location = useLocation();
  return new URLSearchParams({ [RETURN_TO_PARAM]: `${location.pathname}${location.search}` });
}
